import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AsianLogo from "../../../Images/AsianLogo.jpeg";
import catalog from "../../../Images/catalog.pdf";
import "./Header.css"; // Import CSS for Sidebar styling

const Sidebar = ({ isOpen, onClose, handleLogout, setSidebarOpen }) => {
  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("userid");
  const [isOpen1, setIsOpen1] = useState(false);

  const toggleDropdown = () => {
    setIsOpen1(!isOpen1);
  };

  const [isOpen11, setIsOpen11] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen11(!isOpen11);
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <a className="navbar-brand" href="#" style={{ color: "#fff" }}>
        <img src={AsianLogo} style={{ height: "10vh", borderRadius: 10 }} />
      </a>
      <button
        style={{ marginLeft: "55%" }}
        className="navbar-toggler"
        type="button"
        onClick={() => {
          setSidebarOpen(!isOpen);
          handleLogout(); // Call handleLogout here if needed
        }} // Toggle sidebar visibility
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="fas fa-bars" style={{ color: "#fff", fontSize: 24 }} />
      </button>
      {/* <button onClick={onClose} style={{ color: "#fff", background: "none", border: "none", fontSize: "16px", padding: "10px" }}>Close</button> */}
      {/* Add your sidebar content here */}
      <ul>
        <li className="nav-item" style={{ zIndex: 1 }}>
          <a
            className="nav-link active"
            aria-current="page"
            href="/"
            style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
          >
            HOME
          </a>
        </li>
        <li className="nav-item" style={{ zIndex: 1 }}>
          <a
            className="nav-link"
            href="/about-us"
            style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
          >
            ABOUT US
          </a>
        </li>
       
        <li className="nav-item dropdown" style={{ zIndex: 1000 }}>
          <a
            className="nav-link"
            href="#"
            onClick={toggleDropdown1}
            style={{
              color: "#fff",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
        SHOP
          </a>
          {isOpen11 && (
            <ul
              className="dropdown-menu"
              style={{ display: isOpen11 ? "block" : "none" }}
            >
              <li>
                <a className="dropdown-item mobiledropdown" href="/shop">
                  Polytechnic Books
                </a>
              </li>
              <li>
                <a className="dropdown-item mobiledropdown" href="/shop1">
                  D Pharma Books
                </a>
              </li>
              {/* Add more dropdown items as needed */}
            </ul>
          )}
        </li>
        <li className="nav-item" style={{ zIndex: 1 }}>
          <a
            className="nav-link"
            href="/author"
            style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
          >
            AUTHORS
          </a>
        </li>
        <li className="nav-item dropdown" style={{ zIndex: 1 }}>
          <a
            className="nav-link"
            href="#"
            onClick={toggleDropdown}
            style={{
              color: "#fff",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            ORDER FORMS
          </a>
          {isOpen1 && (
            <ul
              className="dropdown-menu"
              style={{ display: isOpen1 ? "block" : "none" }}
            >
              <li>
                <a className="dropdown-item mobiledropdown" href="/orderForm">
                  Polytechnic
                </a>
              </li>
              <li>
                <a className="dropdown-item mobiledropdown" href="/orderForm1">
                  D Pharma
                </a>
              </li>
              {/* Add more dropdown items as needed */}
            </ul>
          )}
        </li>
        <li className="nav-item" style={{ zIndex: 1 }}>
          <a
            className="nav-link"
            href="/becomean-author"
            style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
          >
            BECOME AN AUTHOR
          </a>
        </li>
        <li className="nav-item" style={{ zIndex: 1 }}>
          <a
            download
            className="nav-link"
            href={catalog}
            style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
          >
            OUR CATALOGUE
          </a>
        </li>
        <li className="nav-item" style={{ zIndex: 1 }}>
          <a
            className="nav-link"
            href="/contact-us"
            style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
          >
            CONTACT US
          </a>
        </li>
        {token ? (
          <li className="nav-item" style={{ zIndex: 1 }}>
            <a
              className="nav-link"
              href={`/order-list/${userid}`}
              style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
            >
              HI USER
            </a>
          </li>
        ) : (
          <li className="nav-item" style={{ zIndex: 1 }}>
            <a
              className="nav-link"
              href="/login"
              style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
            >
              LOGIN
            </a>
          </li>
        )}
        {/* <li className="nav-item search-wrapper">
          <a className="nav-link" href="#" style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}>
            <i
              className="fa fa-search"
              onClick={() => { myClass === "search-box" ? setMyClass("search-boxOpen") : setMyClass("search-box"); }}
            />
          </a>
          <div className={myClass}>
            <div className="row">
              <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Search..." onChange={handleChange} />
                <button type="submit">
                  <i className="fa fa-search" />
                </button>
              </form>
            </div>
          </div>
        </li> */}
        {/* <li className="nav-item" style={{ zIndex: 1 }}>
          <a className="nav-link" style={{ color: "#fff", fontSize: 16, fontWeight: 600, marginLeft: 10 }}>
            <i className="fa fa-shopping-cart" onClick={() => navigate("/cart")}>
              <span style={{ backgroundColor: "#fff", borderRadius: "50%", color: "#000", fontSize: "8px", padding: "5px" }}>
                {quantity}
              </span>
            </i>
          </a>
        </li> */}
        {token ? (
          <li className="nav-item" style={{ zIndex: 1 }}>
            <a
              className="nav-link"
              href="#"
              style={{
                color: "#fff",
                fontSize: 16,
                fontWeight: 600,
                marginLeft: 10,
              }}
              onClick={handleLogout}
            >
              <i className="fa fa-sign-out" />
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { quantity } = useSelector((state) => state.cart);
  const [myClass, setMyClass] = useState("search-box");
  const [search, setSearch] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  function handleChange(event) {
    setSearch(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    navigate("/shop", {
      state: search,
    });
  }

  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    navigate("/Login");
  }

  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("userid");
  const [isOpen11, setIsOpen11] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen11(!isOpen11);
  };
  return (
    <>
      <Sidebar
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        setSidebarOpen={setSidebarOpen}
        handleLogout={handleLogout}
      />{" "}
      {/* Sidebar component with open/close props */}
      <div className="navbar navbar-expand-lg bg-body-tertiary sticky-top">
        <div className="container-fluid">
          <div className="col-lg-1" style={{ zIndex: 1 }}>
            <a className="navbar-brand" href="#" style={{ color: "#fff" }}>
              <img
                src={AsianLogo}
                style={{ height: "10vh", borderRadius: 10 }}
              />
            </a>
            <button
              style={{ marginLeft: "55%" }}
              className="navbar-toggler"
              type="button"
              onClick={() => setSidebarOpen(!sidebarOpen)}
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i
                className="fas fa-bars"
                style={{ color: "#fff", fontSize: 24 }}
              />
            </button>
          </div>
          <div className="col-lg-11">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <center>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/* Your existing menu items */}
                  <li className="nav-item" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="/"
                      style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
                    >
                      HOME
                    </a>
                  </li>
                  <li className="nav-item" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link"
                      href="/about-us"
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      ABOUT US
                    </a>
                  </li>
                  <li className="nav-item dropdown" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link"
                      href="#"
                      onClick={toggleDropdown1}
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      SHOP
                    </a>
                    {isOpen11 && (
                      <ul
                        className="dropdown-menu"
                        style={{ display: isOpen11 ? "block" : "none" }}
                      >
                        <li>
                          <a className="dropdown-item" href="/shop">
                            Polytechnic Books
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/shop1">
                            D Pharma Books
                          </a>
                        </li>
                        {/* Add more dropdown items as needed */}
                      </ul>
                    )}
                  </li>
                  <li className="nav-item" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link"
                      href="/author"
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      AUTHORS
                    </a>
                  </li>
                  <li className="nav-item dropdown" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link"
                      href="#"
                      onClick={toggleDropdown}
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      ORDER FORMS
                    </a>
                    {isOpen && (
                      <ul
                        className="dropdown-menu"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        <li>
                          <a className="dropdown-item" href="/orderForm">
                            Polytechnic
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/orderForm1">
                            D Pharma
                          </a>
                        </li>
                        {/* Add more dropdown items as needed */}
                      </ul>
                    )}
                  </li>
                  <li className="nav-item" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link"
                      href="/becomean-author"
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      BECOME AN AUTHOR
                    </a>
                  </li>
                  <li className="nav-item" style={{ zIndex: 1 }}>
                    <a
                      download
                      className="nav-link"
                      href={catalog}
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      OUR CATALOGUE
                    </a>
                  </li>
                  <li className="nav-item" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link"
                      href="/contact-us"
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      CONTACT US
                    </a>
                  </li>
                  {token ? (
                    <li className="nav-item" style={{ zIndex: 1 }}>
                      <a
                        className="nav-link"
                        href={`/order-list/${userid}`}
                        style={{
                          color: "#fff",
                          fontSize: 16,
                          fontWeight: 600,
                          marginLeft: 10,
                        }}
                      >
                        HI USER
                      </a>
                    </li>
                  ) : (
                    <li className="nav-item" style={{ zIndex: 1 }}>
                      <a
                        className="nav-link"
                        href="/login"
                        style={{
                          color: "#fff",
                          fontSize: 16,
                          fontWeight: 600,
                          marginLeft: 10,
                        }}
                      >
                        LOGIN
                      </a>
                    </li>
                  )}
                  <li className="nav-item search-wrapper">
                    <a
                      className="nav-link"
                      href="#"
                      style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}
                    >
                      <i
                        className="fa fa-search"
                        onClick={() => {
                          myClass === "search-box"
                            ? setMyClass("search-boxOpen")
                            : setMyClass("search-box");
                        }}
                      />
                    </a>
                    <div className={myClass}>
                      <div className="row">
                        <form onSubmit={handleSubmit}>
                          <input
                            type="text"
                            placeholder="Search..."
                            onChange={handleChange}
                          />
                          <button type="submit">
                            <i className="fa fa-search" />
                          </button>
                        </form>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item" style={{ zIndex: 1 }}>
                    <a
                      className="nav-link"
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        marginLeft: 10,
                      }}
                    >
                      <i
                        className="fa fa-shopping-cart"
                        onClick={() => navigate("/cart")}
                      >
                        <span
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                            color: "#000",
                            fontSize: "8px",
                            padding: "5px",
                          }}
                        >
                          {quantity}
                        </span>
                      </i>
                    </a>
                  </li>
                  {token ? (
                    <li className="nav-item" style={{ zIndex: 1 }}>
                      <a
                        className="nav-link"
                        href="#"
                        style={{
                          color: "#fff",
                          fontSize: 16,
                          fontWeight: 600,
                          marginLeft: 10,
                        }}
                        onClick={handleLogout}
                      >
                        <i className="fa fa-sign-out" />
                      </a>
                    </li>
                  ) : null}
                </ul>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
