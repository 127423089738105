import React, { useState } from "react";
import AsianLogoText from "../../../Images/AsianLogoText.jpeg";
import login from "../../../Images/Login.png";
import { useNavigate } from "react-router-dom";
import "../../../css/login.css";
import Header from "../header/Header";
import axios from "axios";
import { toastError, toastSuceess } from "../../../util/reactToastify";
import Spinner from "../Spinner";

function Login() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const [forgotPassword, setForgotPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setFormData({ ...formData, email: event.target.value });
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    try {
      setLoader(true);
      const response = await axios.post(
        `https://api.asianpublisher.in/login/forgotpassword?email=${email}`
      );
      if (response.data.message === "OTP sent successfully") {
        setOtpSent(true);
        toastSuceess("OTP sent to your email");

        // Save OTP to local storage
        localStorage.setItem("otp", response.data.otp);
      } else {
        toastError("Failed to initiate forgot password process");
      }
    } catch (error) {
      toastError(
        error.response?.data?.message ||
          "Failed to initiate forgot password process"
      );
    } finally {
      setLoader(false);
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    try {
      setLoader(true);
      // Send a request to verify OTP
      const storedOTP = localStorage.getItem("otp");
      await axios.post(
        `https://api.asianpublisher.in/login/verifyotp?email=${email}&otp=${otp}&storedOTP=${storedOTP}`
      );
      setOtpVerified(true); // Set OTP verification flag
      setOtp(""); // Clear OTP field
      toastSuceess("OTP verified, please enter new password");
    } catch (error) {
      toastError("OTP not verified.");
    } finally {
      setLoader(false);
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      setLoader(true);
      await axios.post(
        `https://api.asianpublisher.in/login/resetpassword?email=${email}&newPassword=${newPassword}`,
        { email, newPassword }
      );
      toastSuceess("Password updated successfully");
      setForgotPassword(false); // Reset the state to hide the "New Password" form
      setOtpSent(false); // Reset OTP sent state
      setOtpVerified(false); // Reset OTP verification state
      setOtp(""); // Clear OTP field
      setNewPassword(""); // Clear new password field
    } catch (error) {
      toastError("Failed to update password");
    } finally {
      setLoader(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    try {
      const tokenResponse = await axios.post(
        "https://api.asianpublisher.in/login/login",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const token = tokenResponse.data.token;
      localStorage.setItem("token", token);

      const id = tokenResponse.data.userId;
      localStorage.setItem("userid", id);

      const userResponse = await axios.get(
        `https://api.asianpublisher.in/api/UserApi?email=${email}&&password=${password}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        userResponse?.data?.message === "Success" &&
        userResponse?.data?.userId
      ) {
        navigate(`/order-list/${userResponse?.data?.userId}`);
      } else {
        toastError("Your email or password is incorrect");
      }
    } catch (error) {
      toastError("An error occurred while logging in");
    }

    setLoader(false);
  };

  return (
    <>
      {loader && <Spinner />}

      <link
        href="https://fonts.googleapis.com/css?family=Karla:400,700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <link rel="stylesheet" href="login.css" />
      <div style={{ display: "none" }}>
        <Header />
      </div>
      <div
        className="container"
        style={{
          marginTop: 30,
          borderRadius: 20,
          boxShadow: "0 10px 30px 0 rgba(172, 168, 168, 0.43)",
          height: "90vh",
          paddingLeft: 0,
        }}
      >
        <div
          className="col-lg-7 HideInPhone"
          style={{
            float: "left",
            backgroundImage: `url(${login})`,
            height: "100%",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        <div
          className="col-lg-5"
          style={{ float: "left", padding: "100px 50px 50px 50px" }}
        >
          <img
            src={AsianLogoText}
            alt="logo"
            style={{ height: "10vh" }}
            className="logo"
          />
          <br />
          <br />
          {!forgotPassword && !otpSent ? (
            <form onSubmit={handleSubmit} style={{ maxWidth: "none" }}>
              <div className="form-group">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  id="email"
                  required
                  className="form-control"
                  placeholder="Email address"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  id="password"
                  required
                  className="form-control"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </div>
              <a
                href="#"
                onClick={() => setForgotPassword(true)}
                style={{ color: "#000", textDecoration: "none" }}
              >
                Forgot Password
              </a>
              <br></br>
              <br></br>
              <center>
                <a href="/"
                  className="login-btn btn-info mb-4"
                  type="submit"
                  value="Go To Home"
                  style={{
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px 10px 20px",
                    textDecoration:"none",
                  }}
                >Go To Home</a>
&nbsp;
                <input
                  name="login"
                  id="login"
                  className="login-btn mb-4"
                  type="submit"
                  value="Sign In Now"
                  style={{
                    backgroundColor: "#D82028",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px 10px 20px",
                  }}
                />
              </center>
            </form>
          ) : forgotPassword && !otpSent ? (
            <form onSubmit={handleForgotPassword}>
              <div className="form-group">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  id="email"
                  required
                  className="form-control"
                  placeholder="Enter your email"
                  onChange={handleEmailChange}
                />
              </div>
              <center>
                <input
                  name="sendOtp"
                  id="sendOtp"
                  className="login-btn mb-4"
                  type="submit"
                  value="Send OTP"
                  style={{
                    backgroundColor: "#D82028",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px 10px 20px",
                  }}
                />
              </center>
            </form>
          ) : otpSent && !otpVerified ? (
            <form onSubmit={handleVerifyOTP}>
              <div className="form-group">
                <label htmlFor="otp" className="sr-only">
                  OTP
                </label>
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  id="otp"
                  required
                  className="form-control"
                  placeholder="Enter OTP"
                  onChange={handleOtpChange}
                />
              </div>
              <center>
                <input
                  name="verifyOtp"
                  id="verifyOtp"
                  className="login-btn mb-4"
                  type="submit"
                  value="Verify OTP"
                  style={{
                    backgroundColor: "#D82028",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px 10px 20px",
                  }}
                />
              </center>
            </form>
          ) : (
            <form onSubmit={handleResetPassword}>
              <div className="form-group">
                <label htmlFor="newPassword" className="sr-only">
                  New Password
                </label>
                <input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  id="newPassword"
                  required
                  className="form-control"
                  placeholder="Enter new password"
                  onChange={handleNewPasswordChange}
                />
              </div>
              <center>
                <input
                  name="resetPassword"
                  id="resetPassword"
                  className="login-btn mb-4"
                  type="submit"
                  value="Reset Password"
                  style={{
                    backgroundColor: "#D82028",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px 10px 20px",
                  }}
                />
              </center>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default Login;
