import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import { fetchBooks } from "../../../redux/slices/bookSlice";
import { fetchAuthors } from "../../../redux/slices/authorSlice";
import { fetchCourses } from "../../../redux/slices/courseSlice";
import { fetchSemesters } from "../../../redux/slices/semesterSlice";
import { addTocart } from "../../../redux/slices/cartSlice";
import { REACT_APP_URL } from "../../../config/config";
import { FaCartPlus, FaShoppingBag } from "react-icons/fa";
import Spinner from "../../common/Spinner";
import Checkbox from "@mui/material/Checkbox";
import ShopHeaderImage from "../../../Images/ShopHeaderImage.png";
import "../../../css/Style.css";
import "../../../css/bootstrap.min.css";

function Shop1() {
  const { loading, books } = useSelector((state) => state.book);
  const { loading: coursesLoading, courses } = useSelector(
    (state) => state.course
  );
  const { loading: semestersLoading, semesters } = useSelector(
    (state) => state.semester
  );
  const { loading: authorsLoading, authors } = useSelector(
    (state) => state.author
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useLocation();
  const [loader, setLoader] = useState(true);
  /*   const [allBooks, setAllBooks] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checkedArr, setCheckedArr] = useState([]);
  const [allAuthors, setAllAuthors] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [allSemesters, setAllSemesters] = useState([]); */
  const [filterAuthors, setFilterAuthors] = useState([]);
  const [filterCourses, setFilterCourses] = useState([]);
  const [filterSemesters, setFilterSemesters] = useState([]);
  const [orderFilter, setOrderFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const departmentFilter = 1;
  const itemsPerPage = 12; // Number of items to display per page
  const author = history.state;

  // Fetch initial data: authors, courses, semesters
  useEffect(() => {
    dispatch(fetchAuthors());
    dispatch(fetchCourses());
    dispatch(fetchSemesters());
  }, [dispatch]);

  /* useMemo(() => {
    if (author && typeof author === typeof "String") {
      if (author !== undefined && author !== "") {
        setSearchFilter(author);
      }
    } else {
      if (author?.id !== undefined && author?.id !== "") {
        setFilterAuthors((prev) => [...prev, author?.id]);
        setCheckedArr((prev) => [...prev, author?.id]);
      }
    }
  }, [author]); */

  // Fetch books when component mounts or filters change
  useEffect(() => {
    dispatch(
      fetchBooks({
        filterAuthors,
        filterCourses,
        filterSemesters,
        orderFilter,
        searchFilter,
        departmentFilter,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
    );
  }, [
    dispatch,
    filterAuthors,
    filterCourses,
    filterSemesters,
    orderFilter,
    searchFilter,
    departmentFilter,
    currentPage,
    itemsPerPage,
  ]);

  // Add book to cart
  const handleCart = (book) => {
    const {
      id,
      authors,
      bookCode,
      courseSemesters,
      image,
      isFeatured,
      languageId,
      languageNav,
      mRP,
      numId,
      name,
    } = book;
    dispatch(
      addTocart({
        product: {
          id,
          quantity: 1,
          authors,
          bookCode,
          courseSemesters,
          image,
          isFeatured,
          languageId,
          languageNav,
          mRP,
          numId,
          name,
        },
      })
    );
  };

  // Handle sorting
  // const handleSort = (e) => {
  //   setOrderFilter(e.target.value);
  // };

  // Checkbox change handlers for courses, semesters, authors
  const handleCheckboxChange = (type, newVal, checked) => {
    switch (type) {
      case "Course":
        setFilterCourses((prev) =>
          checked ? [...prev, newVal.id] : prev.filter((id) => id !== newVal.id)
        );
        break;
      case "Semester":
        setFilterSemesters((prev) =>
          checked ? [...prev, newVal.id] : prev.filter((id) => id !== newVal.id)
        );
        break;
      case "Author":
        setFilterAuthors((prev) =>
          checked ? [...prev, newVal.id] : prev.filter((id) => id !== newVal.id)
        );
        break;
      default:
        break;
    }
  };

  function handleSort(e) {
    setOrderFilter(e.target.value);
    setLoader(true);
  }
  /*   function coursesChangeHandler(e, newVal) {
    setLoader(true);
    setChecked(e.target.checked);
    if (e.target.checked) {
      if (newVal.type === "Course") {
        setFilterCourses((prev) => [...prev, newVal?.id]);
      }
    } else {
      setFilterCourses((prev) => prev.filter((id) => id !== newVal.id));
    }
  }

  function semestersChangeHandler(e, newVal) {
    setLoader(true);
    setChecked(e.target.checked);
    if (e.target.checked) {
      if (newVal.type === "Semester") {
        setFilterSemesters((prev) => [...prev, newVal?.id]);
      }
    } else {
      setFilterSemesters((prev) => prev.filter((id) => id !== newVal.id));
    }
  }

  function authorsChangeHandler(e, newVal) {
    setLoader(true);
    setChecked(e.target.checked);
    if (e.target.checked) {
      if (newVal.type === "Author") {
        setFilterAuthors((prev) => [...prev, newVal?.id]);
        setCheckedArr((prev) => [...prev, newVal?.id]);
      }
    } else {
      setFilterAuthors((prev) => prev.filter((id) => id !== newVal.id));
      setCheckedArr((prev) => prev.filter((id) => id !== newVal.id));
    }
  } */
  // Pagination calculation
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = books.records || []; // Ensure books.records is not undefined

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {loading === "pending" && <Spinner />}
      <Header />
      <div
        className="Headerrowabout"
        style={{
          backgroundImage: `url(${ShopHeaderImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
        }}
      >
        <div className="gradient-overlay gradientoverlaystyle" />
        <div className="col-lg-2" style={{ float: "left" }}>
          &nbsp;
        </div>
        <div className="col-lg-8" style={{ float: "left" }}>
          <div className="video-content aboutusheader">
            <p
              className="text"
              style={{
                textTransform: "uppercase",
                fontSize: "50px",
                fontWeight: "600",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              Shop
            </p>
            <p style={{ fontSize: "20px" }}>
              Asian Publishers is your lifelong Learning Partner. We have
              empowered the growth of Students, Teachers, &amp; Professionals
              since 1981.
            </p>
          </div>
        </div>
        <div className="col-lg-2" style={{ float: "left" }}>
          &nbsp;
        </div>
      </div>
      <br />
      <div
        className="row"
        id="AboutUsSection"
        style={{
          display: "block",
          margin: "0px",
          padding: "0px",
          clear: "both",
        }}
      >
        <div className="col-lg-3" style={{ float: "left" }}>
          <div className="col-lg-12">
            <h4>Authors</h4>
            <hr />
            <div
              className="col-lg-12"
              style={{ height: "80vh", overflowY: "scroll" }}
            >
              {authors?.filter((item) => item.courseName === "D Pharma").map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 10px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      onChange={(e) =>
                        handleCheckboxChange("Author", item, e.target.checked)
                      }
                    />
                    <div>{item.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <br />
          <div className="col-lg-12">
            <h4>Year</h4>
            <hr />
            <div
              className="col-lg-12"
              style={{ height: "14vh", overflowY: "scroll" }}
            >
              {console.log("semesters", semesters)}
              {semesters
                ?.filter(
                  (item) =>
                    item.name !== "First Semester" &&
                    item.name !== "Second Semester" &&
                    item.name !== "Third Semester" &&
                    item.name !== "Fourth Semester" &&
                    item.name !== "Fifth Semester" &&
                    item.name !== "Sixth Semester"
                )
                .map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 10px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        onChange={(e) =>
                          handleCheckboxChange(
                            "Semester",
                            item,
                            e.target.checked
                          )
                        }
                      />
                      <div>{item.name}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="col-lg-9" style={{ float: "left" }}>
          <div className="shop-top-bar">
            <div className="select-shoing-wrap">
              <div className="shop-select col-lg-4">
                <label htmlFor="SortBy">Sort by :</label>&nbsp;&nbsp;
                <select
                  onChange={handleSort}
                  name="SortBy"
                  id="SortBy"
                  className="form-control"
                >
                  <option value="manual">Sort By</option>
                  <option value="titleAscending">Alphabetically, A-Z</option>
                  <option value="titleDescending">Alphabetically, Z-A</option>
                  <option value="priceAscending">Price, low to high</option>
                  <option value="priceDescending">Price, high to low</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row aboutcontent w-100">
            {currentItems.length > 0 ? (
              currentItems.map((book, index) => (
                <div key={index} className="col-lg-4" style={{ float: "left" }}>
                  <center>
                    <div className="book">
                      <img
                        className="book-cover"
                        src={`${REACT_APP_URL}/Image/${book.image}`}
                        alt="Book Cover"
                        onClick={() => navigate(`/BookDetails/${book.id}`)}
                      />
                      <div className="book-inside" />
                    </div>
                  </center>
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "center",
                      marginTop: "15px",
                      fontWeight: "700",
                    }}
                  >
                    <span style={{ fontSize: "12px", fontWeight: "500" }}>
                      ISBN No. {book?.iSBN} &nbsp;&nbsp;
                      <button
                        onClick={() => handleCart(book)}
                        style={{
                          border: "none",
                          fontSize: "18px",
                          backgroundColor: "#fff",
                          color: "red",
                        }}
                      >
                        <FaCartPlus />
                      </button>
                      &nbsp;&nbsp;
                      <button
                        onClick={() => handleCart(book)}
                        style={{
                          border: "none",
                          fontSize: "18px",
                          backgroundColor: "#fff",
                          color: "red",
                        }}
                      >
                        <FaShoppingBag
                          style={{ color: "#000" }}
                          onClick={() => navigate("/checkout")}
                        />
                      </button>
                    </span>
                    <br />
                    {book.name}
                    <br />
                    {book.authors?.length > 0 && (
                      <a href="#" className="remove_href">
                        {book.authors.map((author, index) => (
                          <span
                            key={author.id}
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            {author.name}
                            {index !== book.authors.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </a>
                    )}
                    <br />
                    <span
                      style={{
                        color: "red",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Rs. {book?.mRP}
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <p>No books found</p>
            )}
            <center>
              <br></br>
              <nav
                aria-label="Page navigation example"
                className="paginationstyle"
              >
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 && "disabled"}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage - 1)}
                    >
                      Previous
                    </button>
                  </li>

                  {Array.from(
                    { length: Math.ceil(books.totalRecords / itemsPerPage) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 && "active"
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    )
                  )}

                  <li
                    className={`page-item ${
                      currentPage ===
                        Math.ceil(books.totalRecords / itemsPerPage) &&
                      "disabled"
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </center>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          clear: "both",
          height: "5vh",
          background: "linear-gradient(to top, rgb(216, 32, 40, 0.1), #fff)",
        }}
      />
      <Footer />
    </>
  );
}

export default Shop1;
